import TopLayout from "../TopLayout/TopLayout";
import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../redux/AppContext";
import styles from './TopMostLayout.module.css'
import TopMostNavBar from "../TopMostNavBar/TopMostNavBar";
import {HashRouter as Router, Route, Routes} from "react-router-dom";
import Metrics from "../Metrics/Metrics";
import Project from "../Project/Project";
import Settings from "../Settings/Settings";
import {ThemeContext} from "../../redux/ThemeContext";
import ManagerTop from "../ManagerTop/ManagerTop";
import Feedback from "../Feedback/Feedback";



function TopMostLayout() {
    const { theme } = useContext(ThemeContext);

    useEffect(() => {
        document.body.className = theme;
    }, [theme]);

    
    return (
        <div className={styles["container"]}>
            <div className={styles["navbar"]}>
                <TopMostNavBar/>
            </div>
            <div className={styles["main-app"]}>
                <Routes>
                    <Route path="/metrics" element={<Metrics />} />
                    <Route path="/chat/*" element={<TopLayout />} />
                    <Route path="/workspace" element={<Project />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/*" element={<TopLayout />} />
                </Routes>
            </div>
        </div>
    )


}

export default TopMostLayout;
