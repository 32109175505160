import styles from './Loading.module.css';

function Loading({msg}) {
    return (
        // <div className={`${styles["container"]} code`}>
        //     <div>Loading... {msg}</div>
        // </div>
        <div className={styles.container}>
            <div className={styles.waveText}>
                <div>Opus</div>
                <div>Boost</div>
                <div>for</div>
                <div>Partners</div>
            </div>
        </div>
    )
        ;
}

export default Loading;
